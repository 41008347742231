import React, { memo, Suspense, useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { IS_DEV } from "@/__main__/constants.mjs";
import blitzMessage, { EVENTS, handleMessage } from "@/__main__/ipc-core.mjs";
import { kdaColorStyle } from "@/app/util.mjs";
import {
  cssInGameContainer,
  cssPlayerList,
} from "@/game-lol/components/in-game-template.style.mjs";
import { cssDmgBreakdown } from "@/game-lol/components/in-game-template-damage.style.mjs";
import { cssPlayerCard } from "@/game-lol/components/in-game-template-summoner.style.mjs";
import { CHAMPIONS } from "@/game-lol/constants/champion-classes.mjs";
import {
  MAX_TAGS,
  PREMADE_LABELS,
  RANK_SYMBOLS,
  ROLE_INDEX,
  TEAMS,
} from "@/game-lol/constants/constants.mjs";
import { QUEUE_SYMBOLS } from "@/game-lol/constants/constants-queue-symbols.mjs";
import LoadingScreenRefs from "@/game-lol/LoadingScreen.refs.jsx";
import {
  Container,
  LaneMatchups,
  RightCol,
  TabGrp,
  Teams,
} from "@/game-lol/LoadingScreen.style.jsx";
import {
  getLoadingOverlayMinimized,
  updateLoadingOverlayMinimized,
} from "@/game-lol/utils/actions.mjs";
import championProficiency from "@/game-lol/utils/get-champion-proficiency.mjs";
import getHextechRankIcon from "@/game-lol/utils/get-rank-icon.mjs";
import getRoleIcon from "@/game-lol/utils/get-role-icon.mjs";
import {
  findLocalPlayerTeam,
  parseLiveClientData,
  playstyles,
} from "@/game-lol/utils/in-game-util.mjs";
import {
  createPlaystyleTags,
  renderedTags,
} from "@/game-lol/utils/in-game-vanity-tags.mjs";
import rolesStats from "@/game-lol/utils/roles-stats.mjs";
import Static from "@/game-lol/utils/static.mjs";
import QueueSymbol from "@/game-lol/utils/symbol-queue.mjs";
import SymbolRole from "@/game-lol/utils/symbol-role.mjs";
import {
  aggregatePlayerChampions,
  calculateTeamDamages,
  damageBreakdownUI,
  getStaticData,
  getWinRateColor,
  isArenaQueue,
  isSRQueue,
  isSwarmQueue,
  mapRoleToSymbol,
  playerChampionStats,
  playstylePremades,
} from "@/game-lol/utils/util.mjs";
import Hide from "@/inline-assets/hide-visibility.svg";
import { useReRender } from "@/shared/EventedRender.jsx";
import { classNames } from "@/util/class-names.mjs";
// import { appLog } from "@/util/dev.mjs";
import { formatToPercent, getLocale } from "@/util/i18n-helper.mjs";
import useDOMElement from "@/util/use-dom-element.mjs";
import { useInteractionEvent } from "@/util/use-interaction-event.mjs";
import useKeypress from "@/util/use-key-press.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const TabBtn = (props) => {
  const { active, onClick, disabled } = props;

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={active ? "active type-form--button" : "type-form--button"}
    >
      {props.children}
    </button>
  );
};

const LaneBreakdown = (props) => {
  const { enemyTeam, teammates } = props;
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const state = useSnapshot(readState);
  const {
    lol: {
      overlay: { championStats = [] },
    },
  } = state;

  // First index is important, if no role was requested BE returns all roles
  // the first being the most common
  const metaStats = Object.values(championStats)
    .map((c) => c[0])
    .filter(Boolean);
  const [statKey, setStatKey] = useState("laneWins");

  const sendInteractionEvent = useInteractionEvent("loading-screen");

  const headToHead = useMemo(() => {
    if (!metaStats.length) return [];
    return teammates
      .filter((t) => t?.championId && t?.role)
      .map((teammate) => {
        const opponentId = (enemyTeam || []).find(
          (enemy) => enemy?.role === teammate.role,
        )?.championId;
        const championStats = (metaStats || []).find(
          (c) =>
            c?.role === teammate.role && c?.championId === teammate.championId,
        );
        const matchupStats = (championStats?.matchups || []).find(
          (matchup) => matchup.opponentChampionId === opponentId,
        );

        return {
          role: teammate.role,
          teammateId: teammate.championId,
          opponentId,
          matchupStats,
        };
      });
  }, [metaStats, teammates, enemyTeam]);

  const displayStats = useMemo(() => {
    return headToHead
      .sort(
        (a, b) =>
          ROLE_INDEX[SymbolRole(a.role)] - ROLE_INDEX[SymbolRole(b.role)],
      )
      .map((matchup) => {
        const { role, teammateId, opponentId, matchupStats } = matchup;
        const teammateStat = matchupStats
          ? matchupStats[statKey] / (matchupStats.games || 1)
          : null;
        const opponentStat = teammateStat !== null ? 1 - teammateStat : null;

        return {
          hasMatchup: Boolean(matchupStats),
          role,
          teammateId,
          teammateStat,
          opponentId,
          opponentStat,
        };
      });
  }, [statKey, headToHead]);

  if (headToHead.map((p) => p.matchupStats).every((p) => !p)) return null;

  return (
    <TabGrp>
      <div className="buttons">
        <TabBtn
          active={statKey === "laneWins"}
          onClick={() => {
            setStatKey("laneWins");
            sendInteractionEvent("winrate-widget", {
              tab: "lane",
            });
          }}
        >
          {t("lol:stats.laneWinRate", "Lane Win Rate")}
        </TabBtn>
        <TabBtn
          active={statKey === "wins"}
          onClick={() => {
            setStatKey("wins");
            sendInteractionEvent("winrate-widget", {
              tab: "game",
            });
          }}
        >
          {t("lol:stats.gameWinRate", "Game Win Rate")}
        </TabBtn>
      </div>
      <div className="panel">
        <LaneMatchups>
          {displayStats.map((lane, i) => {
            const RoleIcon = lane.role && getRoleIcon(lane.role);
            const teammateWins = lane.teammateStat >= lane.opponentStat;

            return (
              <li
                key={`${lane.teammateId}:${i}`}
                data-winning-teammate={teammateWins}
                data-no-matchup={lane.teammateStat === null}
                className="matchup"
                style={{
                  "--c1":
                    lane.teammateStat !== null && teammateWins
                      ? "var(--blue)"
                      : "var(--shade3)",
                  "--c2":
                    lane.teammateStat !== null && !teammateWins
                      ? "var(--red)"
                      : "var(--shade3)",
                  "--winner":
                    lane.teammateStat && teammateWins
                      ? "var(--c1)"
                      : "var(--c2)",
                }}
              >
                <img
                  src={Static.getChampionImage(lane.teammateId)}
                  height="32"
                  width="32"
                  className="champ-img teammate"
                />
                <div
                  className="values"
                  style={{
                    "--v1": lane.teammateStat ?? 0.5,
                    "--v2": lane.opponentStat ?? 0.5,
                  }}
                >
                  <div
                    className="val val-left"
                    style={{ flex: lane.teammateStat ?? 0.5 }}
                  >
                    <span className="type-caption--bold">
                      {lane.teammateStat &&
                        formatToPercent(language, lane.teammateStat, {
                          min: 1,
                          max: 1,
                        })}
                    </span>
                  </div>
                  <div
                    className="val val-right"
                    style={{ flex: lane.opponentStat ?? 0.5 }}
                  >
                    <span className="type-caption--bold">
                      {lane.opponentStat &&
                        formatToPercent(language, lane.opponentStat, {
                          min: 1,
                          max: 1,
                        })}
                    </span>
                  </div>
                  <div className="role-icon">{RoleIcon && <RoleIcon />}</div>
                </div>
                <img
                  src={Static.getChampionImage(lane.opponentId)}
                  height="32"
                  width="32"
                  className="champ-img opponent"
                />
              </li>
            );
          })}
        </LaneMatchups>
      </div>
    </TabGrp>
  );
};

const Damage = memo((props) => {
  const { roster, title } = props;
  const state = useSnapshot(readState);
  const {
    lol: {
      overlay: { championStats = [] },
    },
  } = state;
  // First index is important, if no role was requested BE returns all roles
  // the first being the most common
  const metaStats = Object.values(championStats)
    .map((c) => c[0])
    .filter(Boolean);
  if (!metaStats?.length) return null;

  const dmg =
    roster.filter((c) => Boolean(c?.championId)).length &&
    calculateTeamDamages(roster, metaStats, false);
  const dmgUI = damageBreakdownUI(dmg);

  if (!Object.values(dmg).every((d) => d)) return;

  return (
    <div className={cssDmgBreakdown()}>
      {title && <p className="type-caption title">{title}</p>}
      <div className="flex type-title--bold">
        <div
          className="flex-item"
          style={{
            flexGrow: dmgUI.apSize || 1,
            "--dmg-color": dmgUI.apColor,
          }}
        >
          <div className="bar">{dmgUI.apPercentText}</div>
        </div>
        <div
          className="flex-item"
          style={{
            flexGrow: dmgUI.adSize || 1,
            "--dmg-color": dmgUI.adColor,
          }}
        >
          <div className="bar">{dmgUI.adPercentText}</div>
        </div>
        <div
          className="flex-item"
          style={{
            flexGrow: dmgUI.trueSize || 1,
            "--dmg-color": dmgUI.trueColor,
          }}
        >
          <div className="bar">{dmgUI.truePercentText}</div>
        </div>
      </div>
    </div>
  );
});
Damage.displayName = "Damage";

const Premades = memo(({ derivedId, premadeParties = {} }) => {
  const { t } = useTranslation();

  const playerParty = premadeParties[derivedId];

  if (!playerParty) return null;

  const label = PREMADE_LABELS[playerParty.size];

  return (
    <div
      className="premade"
      data-size={playerParty.partySize}
      data-party={playerParty.partyId}
    >
      {new Array(playerParty.partySize).fill(0).map((_, i) => (
        <span key={i} className="dot"></span>
      ))}
      {label && <span className="type-caption--bold label">{t(...label)}</span>}
    </div>
  );
});
Premades.displayName = "Premades";

const BackgroundImage = memo(({ championId, skinId }) => {
  const [bgImg, setBgImg] = useState(
    Static.getChampionCenterSkinImage(championId, skinId),
  );

  function handleBgImgError(ev) {
    const defaultImg = Static.getChampionCenterSkinImage(championId);
    if (ev.target.src !== defaultImg) setBgImg(defaultImg);
  }

  return (
    <div className="bg-img-container">
      <img src={bgImg} onError={handleBgImgError} className="bg-img" />
    </div>
  );
});
BackgroundImage.displayName = "BgImage";

const Player = ({
  player = {},
  isSR,
  isTeammate,
  tagsEnabled,
  playersState = {},
  premadeParties = {},
}) => {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const {
    volatile: { LoLLoadingState = {} },
  } = state;

  const { gameCustomization = {}, championId, skinId, roleSvg } = player || {};

  const role = player.role && mapRoleToSymbol(player.role);

  // summoner id (passed from lcu) or game name (live client) -> everything we care about
  let gameName = "";
  let summonerId = 0;

  if (player.summonerId) {
    summonerId = player.summonerId;
    gameName = playersState.riotGameNameBySummonerId?.[summonerId];
  } else if (championId) {
    summonerId =
      player.summonerId || playersState.summonerIdByChampionId?.[championId];
    gameName = playersState.riotGameNameBySummonerId?.[summonerId];
  }

  const summonerProfile = playersState.accountsBySummonerId?.[summonerId] || {};
  const summonerStats =
    playersState.championStatsBySummonerId?.[summonerId] || [];
  const playstyles = playersState.playStylesBySummonerId?.[summonerId];
  const derivedId = playersState.riotDerivedIdBySummonerId?.[summonerId];

  const dmgRoster = [{ championId, role: role }];

  const { runes = [], secondaryTree, spell1Id, spell2Id } = gameCustomization;
  const hasPosition = !!role;
  const hasRunes = !!runes.length;
  const hasSummonerSpells = Boolean(spell1Id || spell2Id);

  const champions = getStaticData("champions");
  const champion =
    Object.values(champions || []).find((c) => Number(c.key) === championId) ||
    CHAMPIONS[championId];
  const championName = champion?.name || champion?.key || "";

  // Player ranked stats
  const summonerRanks = summonerProfile?.latestRanks || [];
  const currentQueueStats = summonerRanks.find(
    (q) => QueueSymbol(q.queue) === LoLLoadingState.queueId,
  );
  const soloqueueStats = summonerRanks.find(
    (q) => QueueSymbol(q.queue) === QUEUE_SYMBOLS.rankedSoloDuo,
  );
  const queueStats = currentQueueStats || soloqueueStats || {};
  const summonerTier = queueStats?.tier;
  const summonerTierIcon =
    summonerTier && getHextechRankIcon(summonerTier, true);

  // Player champion stats
  const stats = aggregatePlayerChampions(summonerStats);
  const championStats = playerChampionStats(stats, championId);
  const tags = playstyles
    ? createPlaystyleTags(playstyles, role, championId, isSR)
    : [];
  const wrColor =
    championStats.games &&
    getWinRateColor((championStats.wins / championStats.games) * 100);
  const kdaColor = championStats.games && kdaColorStyle(championStats.kda);
  const champProficiency = championProficiency(
    championId,
    championName,
    playstyles,
  );
  const roleProficiency = rolesStats(role, playstyles);

  if (!championId) {
    return <div className={cssPlayerCard()} />;
  }

  return (
    <div
      className={cssPlayerCard()}
      data-in-progress="false"
      data-is-teammate={!!isTeammate}
      data-has-position={hasPosition}
      data-has-runes={hasRunes}
      data-has-summoner-spells={hasSummonerSpells}
      style={{
        "--wr-color": wrColor,
        "--kda-color": kdaColor,
        order: ROLE_INDEX[role],
      }}
    >
      <BackgroundImage championId={championId} skinId={skinId} />
      <Premades derivedId={derivedId} premadeParties={premadeParties} />
      <div className={`rank-border tier-${summonerTier}`}>
        {summonerTierIcon && (
          <div className="rank-icon" data-place="bottom">
            <div dangerouslySetInnerHTML={{ __html: summonerTierIcon }} />
            <span className="type-caption--bold">
              {[
                RANK_SYMBOLS.challenger,
                RANK_SYMBOLS.grandmaster,
                RANK_SYMBOLS.master,
              ].includes(queueStats.tier)
                ? queueStats.leaguePoints
                : queueStats.rank}
            </span>
            <span className="type-caption--bold">
              {t(
                "lol:matchHistory.winsAndLossesWithHypen",
                "{{wins}}W-{{losses}}L",
                {
                  wins: queueStats.wins,
                  losses: queueStats.losses,
                },
              )}
            </span>
          </div>
        )}
      </div>
      <div className="name">
        <span className="player-name type-subtitle1">
          <span className="text">
            {gameName || player.summonerName || t("common:player", "Player")}
          </span>
        </span>
        <span className="champion-name type-mini">{championName || "-"}</span>
      </div>
      <div className="info">
        <div className="summoner-spells">
          <img
            src={Static.getSummonerSpellImageById(spell1Id)}
            width="20"
            height="20"
          />
          <img
            src={Static.getSummonerSpellImageById(spell2Id)}
            width="20"
            height="20"
          />
        </div>
        <div className="champ-container">
          <img
            src={Static.getChampionImage(championId)}
            width="48"
            height="48"
            className="champ-img"
          />
          {isSR && roleSvg ? (
            <div
              className="player-role"
              data-place="top"
              data-tip={roleProficiency.tooltip}
            >
              <div
                className="role-icon"
                dangerouslySetInnerHTML={{ __html: roleSvg }}
              />
              {roleProficiency.icon && (
                <div
                  className="role-proficiency"
                  dangerouslySetInnerHTML={{
                    __html: roleProficiency.icon,
                  }}
                />
              )}
            </div>
          ) : null}
          {isSR && champProficiency.svg ? (
            <div
              className="champion-proficiency"
              data-tip={champProficiency.tooltip}
              dangerouslySetInnerHTML={{ __html: champProficiency.svg }}
            />
          ) : null}
        </div>
        <div className="runes">
          <span className="rune keystone">
            <img src={Static.getRuneImage(runes[0])} width="24" height="24" />
          </span>
          <span className="rune secondary-tree">
            <img
              src={Static.getRuneImage(secondaryTree)}
              width="12"
              height="12"
            />
          </span>
        </div>
      </div>
      {tagsEnabled && (
        <div className="tags">
          {(renderedTags(tags) || []).slice(0, MAX_TAGS).map((tag, i) => (
            <div
              key={`${tag.tagText}:${i}`}
              data-tip={tag.tagTooltip}
              className={tag.tagClass}
            >
              <span className="type-caption--bold text">{tag.tagText}</span>
            </div>
          ))}
        </div>
      )}
      <div className="stats-blocks" data-hide={!summonerStats?.length}>
        <div className="stat-block" data-no-stats={!championId}>
          <div className="champion">
            <img
              src={Static.getChampionImage(championId)}
              width="24"
              height="24"
              loading="lazy"
              className="champ-img"
            />
          </div>
          <div className="winrate">
            <span
              className="stat stat-val type-caption--bold"
              data-highlight={Boolean(
                championStats.wins / (championStats.games || 1) >= 0.7 &&
                  championStats.games > 5,
              )}
            >
              {(championStats.wins / (championStats.games || 1)).toLocaleString(
                getLocale(),
                {
                  style: "percent",
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                },
              )}
            </span>
            <span
              className="stat type-caption"
              data-highlight={Boolean(championStats.games >= 40)}
            >
              {t("common:stats.gamesCountPlayed", "{{count}} Played", {
                count: championStats.games.toLocaleString(getLocale()),
              })}
            </span>
          </div>
          <div className="kda">
            <span className="stat stat-val type-caption--bold">
              {championStats.games
                ? t("lol:matchTile.kda", "{{kda}} KDA", {
                    kda: championStats.kda.toLocaleString(getLocale(), {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }),
                  })
                : "-"}
            </span>
            <span className="stat type-caption">
              {t("lol:displayKDA", "{{kills}} / {{deaths}} / {{assists}}", {
                kills: (
                  championStats.kills / (championStats.games || 1)
                ).toLocaleString(getLocale(), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
                deaths: (
                  championStats.deaths / (championStats.games || 1)
                ).toLocaleString(getLocale(), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
                assists: (
                  championStats.assists / (championStats.games || 1)
                ).toLocaleString(getLocale(), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
              })}
            </span>
          </div>
        </div>
      </div>
      <div className="damage" data-hide={!championId}>
        <Damage hide={!championId} roster={dmgRoster} />
      </div>
    </div>
  );
};

const LoadingScreen = () => {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const {
    volatile: { LoLLoadingState = {}, LoLLiveClient = {} },
  } = state;

  const { playersState = {} } = LoLLoadingState;

  const localPlayer = (LoLLiveClient?.allPlayers || []).find(
    (player) =>
      player.summonerName === LoLLiveClient.activePlayer?.summonerName,
  );

  const enemyTeamId =
    LoLLoadingState?.localPlayerTeam === TEAMS.BLUE.int
      ? TEAMS.RED.riot
      : TEAMS.BLUE.riot;

  const [hidden, setHidden] = useState(getLoadingOverlayMinimized());

  const adElement = useDOMElement(LoadingScreenRefs.videoAd);
  const battlesPromoElement = (
    <Suspense key="battles-promo">
      <LoadingScreenRefs.battlesPromo
        LoLLiveClient={LoLLiveClient}
        minimized={hidden}
      />
    </Suspense>
  );
  const premiumPromoElement = !adElement ? (
    <Suspense key="premium-promo">
      <LoadingScreenRefs.premiumPromo />
    </Suspense>
  ) : null;
  const promoElements = [battlesPromoElement, premiumPromoElement].filter(
    Boolean,
  );

  useReRender();

  const sendInteractionEvent = useInteractionEvent("loading-screen");

  const toggleHidden = useCallback(
    (e) => {
      e?.preventDefault();

      setHidden((oldHidden) => {
        const newHidden = !oldHidden;
        updateLoadingOverlayMinimized(newHidden);
        sendInteractionEvent("toggle-visibility", {
          visible: newHidden,
        });
        return newHidden;
      });
    },
    [setHidden, sendInteractionEvent],
  );

  useKeypress("Space", toggleHidden);

  const openProPage = useCallback(() => {
    blitzMessage(EVENTS.OPEN_PRO_PAGE);
    sendInteractionEvent("open-pro-page");
  }, [sendInteractionEvent]);

  // Just adding the ability to override the queue in dev for debugging
  const [devQueue, setDevQueue] = useState(!IS_DEV ? null : QueueSymbol(420));
  const queue = devQueue || QueueSymbol(LoLLoadingState?.queueId);
  const isSR = IS_DEV
    ? isSRQueue(devQueue)
    : isSRQueue(devQueue || LoLLoadingState?.queueId) ||
      isSRQueue(
        null,
        LoLLiveClient?.gameData?.gameMode,
        LoLLiveClient?.gameData?.mapNumber,
      );

  const TAGS_ENABLED =
    queue === QUEUE_SYMBOLS.rankedSoloDuo ||
    queue === QUEUE_SYMBOLS.normalDraft;

  const isArena = isArenaQueue(queue);
  const isSwarm = isSwarmQueue(queue);

  // FIXME: temporary solution? the overlay itself should unload
  // rather than toggling visibility
  handleMessage(EVENTS.LOL_TOGGLE_LOADING_VISIBILIY, () => {
    toggleHidden();
  });

  const activePlayerTeam = isArena
    ? null
    : LoLLiveClient?.activePlayer
      ? findLocalPlayerTeam(
          LoLLiveClient?.activePlayer,
          LoLLiveClient?.allPlayers,
        )
      : null;

  // Teammates
  const teammatesRendered = useMemo(() => {
    let team = [];

    if (
      isArena &&
      playersState?.teamOne &&
      typeof playersState.teamOne === "object"
    ) {
      team = Object.values(playersState.teamOne).slice(0, 8);
    } else if (
      playersState?.teamOne &&
      typeof playersState.teamOne === "object"
    ) {
      team = Object.values(playersState.teamOne);
    } else if (LoLLiveClient?.allPlayers?.length && activePlayerTeam) {
      team = LoLLiveClient.allPlayers.filter(
        (p) => p.team === activePlayerTeam,
      );
    }

    return parseLiveClientData(team, false);
  }, [isArena, playersState, LoLLiveClient, activePlayerTeam]);

  // Enemies
  const enemyTeamRendered = useMemo(() => {
    let team = [];

    if (
      isArena &&
      playersState?.teamOne &&
      typeof playersState.teamOne === "object"
    ) {
      team = Object.values(playersState.teamOne).slice(-8);
    } else if (
      playersState?.teamTwo &&
      typeof playersState.teamTwo === "object"
    ) {
      team = Object.values(playersState.teamTwo);
    } else if (LoLLiveClient?.allPlayers?.length && activePlayerTeam) {
      team = LoLLiveClient.allPlayers.filter(
        (p) => p.team !== activePlayerTeam,
      );
    }

    return parseLiveClientData(team, false);
  }, [isArena, playersState, LoLLiveClient, activePlayerTeam]);

  const premadeParties = useMemo(() => {
    const { riotDerivedIdBySummonerId = {}, playStylesBySummonerId = {} } =
      playersState;

    const playersPlaystyles = playstyles({
      players: Object.keys(riotDerivedIdBySummonerId),
      playstylesData: playStylesBySummonerId,
      derivedIds: riotDerivedIdBySummonerId,
      region: LoLLoadingState.region,
    });

    const premades = playstylePremades(playersPlaystyles);

    return premades;
  }, [playersState, LoLLoadingState]);

  const teamSize = isArena ? 8 : 5;

  if (
    !teammatesRendered.filter((p) => p?.championId).length ||
    isArena ||
    isSwarm
  ) {
    return null;
  }

  return (
    <Container
      style={{ "--players-per-team": teamSize }}
      className={`${cssInGameContainer()} overlay ${
        hidden ? "overlay-hidden" : ""
      }`}
    >
      <div className="card">
        <div>
          <button
            {...classNames(
              "visibility-btn",
              "type-form--button",
              hidden && "minimize",
            )}
            onClick={toggleHidden}
          >
            <Hide />
            <span>
              {hidden
                ? t("common:spaceToShowStats", "Press Space to Show Stats")
                : t("common:spaceToHideStats", "Press Space to Hide Stats")}
            </span>
          </button>
          <div className="promo-container minimized">
            {hidden ? promoElements.map((element) => element) : null}
          </div>
        </div>
        <div className="content">
          <Teams>
            <div
              className={`${cssPlayerList()} ${
                enemyTeamId === TEAMS.BLUE.riot ? "lower-team" : "upper-team"
              }`}
            >
              {[...new Array(teamSize)].map((_, index) => {
                const player = teammatesRendered[index];
                return (
                  <Player
                    isTeammate
                    key={`teammate:${player?.championId}:${index}`}
                    index={index}
                    player={player}
                    isSR={isSR}
                    tagsEnabled={TAGS_ENABLED}
                    playersState={playersState}
                    premadeParties={premadeParties}
                  />
                );
              })}
            </div>
            <div
              className={`${cssPlayerList()} ${
                enemyTeamId === TEAMS.BLUE.riot ? "upper-team" : "lower-team"
              }`}
            >
              {[...new Array(teamSize)].map((_, index) => {
                const player = enemyTeamRendered[index];
                return (
                  <Player
                    key={`opponent:${player?.championId}:${index}`}
                    index={index}
                    player={player}
                    isSR={isSR}
                    tagsEnabled={TAGS_ENABLED}
                    playersState={playersState}
                    premadeParties={premadeParties}
                  />
                );
              })}
            </div>
          </Teams>
          <RightCol>
            {![...teammatesRendered, ...enemyTeamRendered]
              .map((p) => p?.championId)
              .every((p) => !p) && (
              <>
                {IS_DEV && (
                  <TabGrp>
                    <div className="buttons">
                      <TabBtn
                        active={devQueue === QueueSymbol(420)}
                        onClick={() => setDevQueue(QueueSymbol(420))}
                      >
                        {`ranked`}
                      </TabBtn>
                      <TabBtn
                        active={devQueue === QueueSymbol(400)}
                        onClick={() => setDevQueue(QueueSymbol(400))}
                      >
                        {`draft`}
                      </TabBtn>
                      <TabBtn
                        active={devQueue === QueueSymbol(450)}
                        onClick={() => setDevQueue(QueueSymbol(450))}
                      >
                        {`aram`}
                      </TabBtn>
                    </div>
                  </TabGrp>
                )}
                <TabGrp>
                  <div
                    className="buttons"
                    style={{
                      gridTemplateColumns: "unset",
                      pointerEvents: "none",
                    }}
                  >
                    {/* <TabBtn active={true}>
                      {t("lol:abilities.damageBreakdown", "Damage Breakdown")}
                    </TabBtn> */}
                    {/* <TabBtn disabled>
                      {t("common:benchmarkGoals", "Benchmark Goals")}
                    </TabBtn> */}
                  </div>
                  {isArena ? (
                    <div className="panel">
                      <Damage
                        className={
                          enemyTeamId === TEAMS.BLUE.id
                            ? "lower-team"
                            : "upper-team"
                        }
                        title={t(
                          "lol:lobbyDamageBreakdown",
                          "Lobby Damage Breakdown",
                        )}
                        roster={[
                          ...teammatesRendered,
                          ...enemyTeamRendered,
                        ].filter(
                          (p) => p.summonerName !== localPlayer?.summonerName,
                        )}
                      />
                    </div>
                  ) : (
                    <div className="panel">
                      <Damage
                        className={
                          enemyTeamId === TEAMS.BLUE.id
                            ? "lower-team"
                            : "upper-team"
                        }
                        title={t(
                          "lol:abilities.teamDamageBreakdown",
                          "Team Damage Breakdown",
                        )}
                        roster={teammatesRendered}
                      />
                      <Damage
                        className={
                          enemyTeamId === TEAMS.BLUE.id
                            ? "upper-team"
                            : "lower-team"
                        }
                        title={t(
                          "lol:abilities.enemyDamageBreakdown",
                          "Enemy Damage Breakdown",
                        )}
                        roster={enemyTeamRendered}
                      />
                    </div>
                  )}
                </TabGrp>
                {!isArena && (
                  <LaneBreakdown
                    enemyTeam={enemyTeamRendered}
                    teammates={teammatesRendered}
                  />
                )}
                <div className="promo-container">
                  {promoElements.map((element) => element)}
                </div>
              </>
            )}
            {adElement && (
              <div className="video-ad-container">
                <div className="video-ad-header">
                  <span className="type-overline ad-text">
                    {t("ads:advertisement", "Advertisement")}
                  </span>
                  <button className="visibility-btn" onClick={openProPage}>
                    <span className="cta-text type-caption--bold">
                      <Trans i18nKey="ads:removeAds">
                        Remove Ads -{" "}
                        <span id="upgrade-cta">Upgrade to Premium</span>
                      </Trans>
                    </span>
                  </button>
                </div>
                {adElement}
              </div>
            )}
          </RightCol>
        </div>
      </div>
    </Container>
  );
};

export function meta() {
  return {
    title: [null, "League of Legends - Loading Screen"],
    description: [null, "League of Legends Loading Screen Overlay"],
  };
}

export default LoadingScreen;
